import React, {useState,useEffect,useRef} from "react";
import { Box, Tooltip,  } from "@mui/material";
import PropTypes from "prop-types";
import Typography from '@mui/material/Typography';
import { customUseSelector } from "../../store/customUseSelector";
import {formatDateTime} from '../authKeyComponents/authKeyTable/authKeyTable'
import "./rowHistoryPopup.scss"
import ListItem from '@mui/material/ListItem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {Select} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import UndoIcon from '@mui/icons-material/Undo';
import { useDispatch } from "react-redux";
import { updateCells, restoreDeletedRow, getDeletedRows1, getRowHistory1 } from "../../store/table/tableThunk";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import RowData from "../rowData/rowData";
import { InfiniteLoader, List } from 'react-virtualized';
import "./rowHistoryPopup.scss";

function RowHistoryPopup(props) {
  const {open , handleClose} = props;
  const tables = customUseSelector((state) => state.tables); // data from redux
  const table = customUseSelector((state) => state.table); // data from redux
  const dispatch = useDispatch();
  const orgUsers = customUseSelector((state) => state.dataBase.currentOrgUsers);
  const authKeys = tables.authKeys || {};
  const params = useParams();
  let rowHistory = [];
  let allfields = "all#fields";
  let [field, setField] = useState(allfields);
  const [pageNo, setPageNo] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [status, setStatus] = useState('pending');
  // const [loadingMore, setLoadingMore] = useState(false);
  const listRef = useRef(null);

  let rowData;
  if (props?.autonumber) {
    rowData = customUseSelector(state => state?.table?.data[props.rowIndex] ?? {});
    var history = [...(table?.rowHistory || [])];
    var filteredHistory = history.filter(row => field === allfields || row.fieldid === field);
    rowHistory = filteredHistory;
  } else {
    rowHistory = [];
    rowHistory = table.deletedRows ? [...table.deletedRows] : []
  }

  useEffect(() => {
    const fetchData = async () => {
      const { dbId, tableName } = params;
      let result;
      if (!props.autonumber) {
         result = await dispatch(getDeletedRows1({ dbId: dbId, tableName: tableName, pageNo: pageNo }));
      } else {
        const autonumber = props.autonumber;
        if (autonumber > 0) {
           result = await dispatch(getRowHistory1({ dbId: dbId, autonumber: autonumber, tableName: tableName,fieldId: field === allfields ? undefined: field , pageNo: pageNo }));
        }
      }
      if (!result?.payload?.hasMoreRows) {
        setHasMore(false);
      }
      setStatus(result.meta.requestStatus)
    };
    fetchData();
  }, [pageNo,field]);

const revertChange = (fieldId, value) => {
  let field , isAttachment, prevState, rowDataFieldValue;
  if(props?.autonumber){
  const updatedArray = [];
   field = tables.tables[params.tableName].fields[fieldId];
   prevState = value;
   rowDataFieldValue = rowData[fieldId];
  const isMultipleSelect = field.fieldType === "multipleselect";
   isAttachment = field.fieldType === "attachment";
  if (isMultipleSelect){
    let { addedLinks, deletedLinks } = getAddedAndDeletedLinks(true, rowDataFieldValue, value.value);
    if ( addedLinks.length > 0) {
      updatedArray.push(createUpdateObject(fieldId, props.autonumber, 'add', addedLinks));
    }
    if ( deletedLinks.length > 0) {
      updatedArray.push(createUpdateObject(fieldId, props.autonumber, 'delete', deletedLinks));
    } 
  }

  if (typeof(rowDataFieldValue)==Array && typeof(prevState.value)==Array  && rowDataFieldValue?.join(',') === prevState.value.join(',')) {
    toast.info(`${fieldId} is already set to the desired value.`);
    return;
  }else if(rowDataFieldValue===prevState.value){
    toast.info(`${fieldId} is already set to the desired value.`);
    return;
  }


  const { addedLinks, deletedLinks } = getAddedAndDeletedLinks(isAttachment, rowDataFieldValue, prevState.value);
  if (isAttachment && addedLinks.length > 0) {
    updatedArray.push(createUpdateObject(fieldId, props.autonumber, 'restore', addedLinks));
  }
  if (isAttachment && deletedLinks.length > 0) {
    updatedArray.push(createUpdateObject(fieldId, props.autonumber, 'delete', deletedLinks));
  } else if (!isAttachment && !isMultipleSelect && rowDataFieldValue !== value) {
    updatedArray.push(createUpdateObject(fieldId, props.autonumber, 'update', value));
  }

  let newArray=[];
  if(!isAttachment && !isMultipleSelect){
        newArray = updatedArray.map(obj => ({
          ...obj,
          fields: {
            [fieldId]: 
            obj.fields[fieldId].update.value
          }
        })
    );
  }
  else 
  {
    newArray=updatedArray;
  }
  if(newArray.length===0) {
    toast.success(`already have the desired value`);
  }else{
  dispatchAndUpdateCells(newArray, fieldId);
}
}else{
  dispatch(restoreDeletedRow({
    value,
  })).then((res)=>{
      if(!res.error){
        toast.success(`successfully restored row`);
      }
    });
    handleClose();
}
}

const getAddedAndDeletedLinks = (isAttachment, currentLinks, previousLinks) => {
  if (!isAttachment) {
    return { addedLinks: [], deletedLinks: [] };
  }
  let addedLinks=[];
  let deletedLinks=[];
  const currentStateSet = new Set(currentLinks);
  if(previousLinks.add){
    addedLinks = previousLinks.add.filter(link => !currentStateSet.has(link));
  }else if (previousLinks.delete){
    deletedLinks = previousLinks.delete.filter(link => currentStateSet.has(link));
  }else if(previousLinks.restore){
    addedLinks = previousLinks.restore.filter(link => !currentStateSet.has(link));
    
  }
  return { addedLinks, deletedLinks };
};

const createUpdateObject = (fieldId, autonumber, action, links) => {
  return {
    where: `autonumber = ${autonumber}`,
    fields: { [fieldId]: { [action]: links } },
  };
};

const dispatchAndUpdateCells = (updatedArray, fieldId) => {
  dispatch(updateCells({
    updatedArray,
    indexIdMapping: { [props.autonumber]: props.rowIndex },
    oldData: rowData[fieldId]
  })).then((res) => {
    if (!res.error) {
      toast.success(`Updated ${fieldId} successfully`);
    }
  });
};

  const isRowLoaded = ({ index }) => {
    return !hasMore || index < rowHistory.length;
  };

  const loadMoreRows = () => {
    if (hasMore) {
      setPageNo((prevPageNo) => prevPageNo + 1);
    }
  };

  const rowRenderer = ({ index, key, style }) => {
    if (!isRowLoaded({ index })) {
      return (
        <div key={key} style={style}>
          <CircularProgress color="inherit" />
        </div>
      );
    }

    const history = rowHistory[index];
    const updatedBy = history['updatedby']
    const user = orgUsers?.[updatedBy];
    const time = formatDateTime(history['updatedat'] * 1000);
    return (
      <ListItem key={key} style={style} className="edited-item">
        <Box width="90%">
          <Box className="edited-by">
            <Typography className="edited-name">
              {user?.name || (authKeys[updatedBy] ? updatedBy : 'Deleted User or Key')}
              {' ' + history['data']['event'] + 'd ' + 'this record'}
            </Typography>
            <Typography className="edited-time">{time}</Typography>
          </Box>
          {history['data']['event'] !== 'restore' && (
            <RowData value={history.data?.event === 'delete' ? history.data?.value : { [history.fieldid]: history.data.value }} heading={history.autonumber} />
          )}
        </Box>
        {history['data']['event'] !== 'restore' && (
          <Tooltip className="revert-button" title="Revert to this change">
            <IconButton
              size="large"
              onClick={() => {
                if (props?.autonumber) {
                  revertChange(history['fieldid'], history['data']);
                } else {
                  revertChange(0, history);
                }
              }}
            >
              <UndoIcon />
            </IconButton>
          </Tooltip>
        )}
      </ListItem>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg" >
      <Box className="row-history-dialog" >
        <DialogTitle className="row-history-header" variant="h4">
          History of Row {props?.autonumber || ''}
          <IconButton onClick={handleClose} className="close-button">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box className="row-history" ref={listRef}>
          {status === 'pending'  && <CircularProgress color="inherit" />}
          {status === 'fulfilled' && (
            <Box className="success-div">
              {props?.autonumber && (
                <Box className="field-selector">
                  <Typography>Choose a specific Field :</Typography>
                  <Select className="field-dropdown" value={field} onChange={(e) => {
                    setField(e.target.value)
                    setPageNo(1);
                    setHasMore(true);
                    setStatus('pending');
                  }
                    }>
                    <MenuItem key={allfields} value={allfields}>
                      All Fields
                    </MenuItem>
                    {props.fields.map((field) => {
                      return (
                        <MenuItem key={field.id} value={field.id}>
                          {field.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Box>
              )}
              {rowHistory.length > 0 ? (
                <Box maxWidth="lg">
                  <InfiniteLoader
                    isRowLoaded={isRowLoaded}
                    loadMoreRows={loadMoreRows}
                    rowCount={hasMore ? rowHistory.length + 1 : rowHistory.length}
                  >
                    {({ onRowsRendered, registerChild }) => (
                      <List
                        height= {500}
                        width={950}
                        rowHeight={155}
                        rowCount={rowHistory.length}
                        rowRenderer={rowRenderer}
                        onRowsRendered={onRowsRendered}
                        ref={(list) => {
                          listRef.current = list;
                          registerChild(list);
                        }}
                      />
                    )}
                  </InfiniteLoader>
                </Box>
              ) : (
                <Typography className="no-history" variant="h6">
                  No Revision History Found
                </Typography>
              )}
            </Box>
          )}
          {status === 'rejected' && <Typography>Failed to fetch data</Typography>}
        </Box>
      </Box>
    </Dialog>
  );
}

RowHistoryPopup.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  autonumber: PropTypes.number,
  fields: PropTypes.array,
  rowIndex: PropTypes.number,
};
export default RowHistoryPopup;